<template>
  <div>
    <div class="banner">
      <div class="logo"></div>
      <h2>找 大 咖 · 就 上 师 董 会</h2>
      <div class="ios" @click="stop"></div>
      <div class="android" @click="stop"></div>
      <!-- <div v-show="banner1Code" class="qrcode-right"></div> -->
    </div>
    <div class="banner2">
      <div>
        <h3 class="app">APP</h3>
        <h3 class="highlight">介绍</h3>
      </div>
      <div>
        <div class="info">
          这是一款由师董会自主研发的能够将智慧共享、变现的学习交流型APP，可实现名师答疑、
          在线路演、资源展现、音视频学习等功能。平台有注册导师及专家1432人，有下载用户1400万人。
        </div>
      </div>
      <div class="left">
        <div class="box bt">
          <img class="icon" src="../../assets/download-icon10.png" />
          <p class="title">名师问答</p>
          <p class="text">
            1432位专家导师1对1互动问答，申请认证专家即可获得问答收益
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon8.png" />
          <p class="title">师董直播</p>
          <p class="text">
            每周推送大咖实战对话直播节目，也可回看往期线下活动视频
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon11.png" />
          <p class="title">名师音频</p>
          <p class="text">
            独家导师专家的音频课程系列，涵盖投资、营销、创业多个领域
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon12.png" />
          <p class="title">线下活动</p>
          <p class="text">
            对当期开展的线下活动进行线上报名，也可查看往期活动介绍
          </p>
        </div>
      </div>
      <div class="right">
        <div class="box bt">
          <img class="icon" src="../../assets/download-icon13.png" />
          <p class="title">在线咨询</p>
          <p class="text">
            可上传企业、产品、项目的路演资料，通过审核后可全员阅览
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon15.png" />
          <p class="title">名师课程</p>
          <p class="text">
            实战导师专家精品视频课程，涵盖管理、品牌、财务多个系列
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon14.png" />
          <p class="title">师董资源</p>
          <p class="text">
            对会员企业进行全面展示，可通过筛选搜索获得更精准资源
          </p>
        </div>
        <div class="box">
          <img class="icon" src="../../assets/download-icon9.png" />
          <p class="title">企业诊断</p>
          <p class="text">填报企业运营情况及想咨询的问题，可获得企业诊断反馈</p>
        </div>
      </div>
    </div>
    <div class="banner3"></div>
    <div class="banner4">
      <div>
        <h3 class="app">APP</h3>
        <h3 class="highlight">优势</h3>
      </div>
      <div class="line"></div>
      <div class="banner4_box">
        <div class="one">
          <div class="title">资源共享</div>
          <div class="text">
            平台所有导师资源、会员资源、项目资源以及问答的收听收看、各系列课程等均为用户共享模式，注册会员所上传的路演、直播均可全员传阅，资讯可以大程度的传播。在享受资源共享的同时，将收获更多发展机遇，挖掘全新合作模式。
          </div>
        </div>
        <div class="two">
          <div class="title">精准社群</div>
          <div class="text">
            专门为白金以上级别用户开放的“白金用户群”，是以企业家为主体人群的实名制即时通讯群，高度提升了有效社交的质量。多达千人的大群，为白金用户...
          </div>
        </div>
        <div class="three">
          <div class="title">高效对接</div>
          <div class="text">
            通过超精细的行业细分，注册用户可快速匹配到项目专家进行咨询。师董会拥有超百人的服务团队，将协助会员对接导师，解决企业各类商业需求。会员也...
          </div>
        </div>
      </div>
    </div>
    <div class="banner5">
      <h2>找大咖·就上师董会</h2>
      <h3>专业名师解答疑难问题</h3>
      <div class="ios" @click="stop"></div>
      <div class="android" @click="stop"></div>
    </div>
    <div class="banner6">
      <div>
        <h3 class="app">探索</h3>
        <h3 class="highlight">“发现”</h3>
      </div>
      <div>
        <div class="info">
          为提升用户体验，APP在“发现”中设置了更多个性化功能，使APP社交更具开放性，功能更加多元化
        </div>
      </div>
      <div class="left">
        <div class="lt">
          <img class="icon" src="../../assets/download-icon1.png" />
          <span class="title">开启直播</span>
          <div class="text">
            实名认证后可开启线上直播，同时设置
            直播预告，平台千万级流量，可以让好 的想法和创意获得更多展示和关注；
          </div>
        </div>
        <div class="lb">
          <img class="icon" src="../../assets/download-icon2.png" />
          <span class="title">第三方服务</span>
          <div class="text">
            丰富的第三方服务联盟阵容，将为会员
            个人及企业提供覆盖更广、细分更全面 的其它领域的服务；
          </div>
        </div>
      </div>
      <div class="right">
        <div class="rt">
          <img class="icon" src="../../assets/download-icon3.png" />
          <span class="title">师董圈</span>
          <div class="text">
            是分享想法的开放式留言板，全员均可
            进行公开评论、点赞，遇到志同道合的 朋友还可互相关注、添加好友；
          </div>
        </div>
        <div class="rb">
          <img class="icon" src="../../assets/download-icon4.png" />
          <span class="title">紧急求助</span>
          <div class="text">
            可上传各类紧急问题及需求，享受1400
            万下载注册用户共同为你出谋划策，注 册会员均可体验该服务；
          </div>
        </div>
      </div>
    </div>
    <div class="banner7">
      <div>
        <h3 class="app">精选</h3>
        <h3 class="highlight">课程</h3>
      </div>
      <div>
        <div class="info">
          一款由师董会自主研发的能够将智慧共享、变现的学习交流型APP，可实现名师答疑、
          在线路演、资源展现、音视频学习等功能。平台有注册导师及专家1432人，有下载用户1400万人。
        </div>
      </div>
      <div class="banner7_box">
        <div class="picBox">
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic2.png" />
          </div>
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic3.png" />
          </div>
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic4.png" />
          </div>
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic1.png" />
          </div>
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic6.png" />
          </div>
          <div class="picImg">
            <img class="pic" src="../../assets/download-pic5.png" />
          </div>
        </div>
      </div>
      <div>
        <div class="btn" @mouseover="qrcode = true" @mouseout="qrcode = false">
          想查看更多资源，下载师董会APP
          <div v-show="qrcode" class="qrcode"></div>
        </div>
      </div>
    </div>
    <div class="banner8">
      <h3 class="app">下载师董会APP</h3>
      <h3 class="highlight">解锁更多玩法</h3>
      <div class="info">
        大 | 咖 | 引 | 领 | 的 | 智 | 慧 | 共 | 享 | 及 | 企 | 业 | 互 | 助 | 平
        | 台
      </div>
      <img class="qrcode" src="../../assets/app2.png" />
      <img class="input" src="../../assets/download-input.png" />
    </div>
    <div class="banner9">
      <div class="modal" v-show="dialogVisible" @touchmove.prevent>
        <div class="modalcode">
          <div class="code"></div>
          <div class="closed" @click="move">
            <img src="../../assets/close2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      qrcode: false,
      banner1Code: false,
      banner5Code: false,
      dialogVisible: false,
    };
  },
  created() {
    document.addEventListener("click", (e) => {
      if (document.getElementsByClassName("ios")[0]) {
        if (
          !document.getElementsByClassName("ios")[0].contains(e.target) &&
          !document.getElementsByClassName("android")[0].contains(e.target) &&
          !document.getElementsByClassName("ios")[1].contains(e.target) &&
          !document.getElementsByClassName("android")[1].contains(e.target)
        ) {
          this.banner1Code = false;
          this.banner5Code = false;
        }
      }
    });
  },
  methods: {
    //禁止滚动
    stop() {
      this.dialogVisible = true;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      this.dialogVisible = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
  },
};
</script>

<style lang="less" scoped>
.qrcode-right {
  width: 210px;
  height: 198px;
  background-image: url(../../assets/qrcode-right.png);
  background-position: center;
  background-size: cover;
  position: absolute;
}
.banner {
  height: 744px;
  background-image: url(https://cdn.sdh365.com/ow/download-banner3.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  .logo {
    width: 130px;
    height: 130px;
    background-image: url(../../assets/download-logo.png);
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 133px;
    right: 616px;
  }
  h2 {
    line-height: 43px;
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 1px;
    position: absolute;
    top: 283px;
    right: 460px;
  }
  .ios {
    position: absolute;
    width: 405px;
    height: 70px;
    background-image: url(../../assets/download-ios.png);
    background-position: center;
    background-size: cover;
    top: 390px;
    right: 478px;
    cursor: pointer;
  }
  .android {
    position: absolute;
    width: 405px;
    height: 70px;
    background-image: url(../../assets/download-android.png);
    background-position: center;
    background-size: cover;
    top: 484px;
    right: 478px;
    cursor: pointer;
  }
  .qrcode-right {
    top: 370px;
    right: 270px;
  }
}
.banner2 {
  padding-top: 22px;
  padding-bottom: 30px;
  text-align: center;
  h3 {
    display: inline-block;
    line-height: 67px;
    font-size: 48px;
    font-weight: 800;
    color: #333333;
  }
  .highlight {
    color: #ff7b00;
  }
  .info {
    display: inline-block;
    width: 1130px;
    line-height: 36px;
    font-size: 26px;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
    margin-bottom: 30px;
    text-indent: 2em;
  }
  .left,
  .right {
    display: inline-block;
    text-align: left;
    .box {
      width: 580px;
      border-bottom: 1px solid #dddddd;
      padding-top: 20px;
      padding-left: 48px;
      padding-bottom: 26px;
      position: relative;
      .title {
        line-height: 34px;
        font-size: 24px;
        font-weight: 700;
        color: #333333;
        margin-bottom: 10px;
      }
      .text {
        color: #666666;
      }
      .icon {
        width: 36px;
        height: 36px;
        position: absolute;
        left: 0;
        /*grayscale(val):val值越大灰度就越深*/
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
      }
    }
    .box:hover {
      .title {
        color: #ff7b00;
      }
      .text {
        color: #ff7b00;
      }
      .icon {
        -webkit-filter: grayscale(0);
        -moz-filter: grayscale(0);
        -ms-filter: grayscale(0);
        -o-filter: grayscale(0);
        filter: grayscale(0);
      }
    }
    .bt {
      border-top: 1px solid #dddddd;
    }
  }
  .right {
    margin-left: 40px;
  }
}
.banner3 {
  height: 753px;
  background-image: url(https://cdn.sdh365.com/ow/download-banner.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.banner4 {
  height: 606px;
  background: #ffffff;
  padding-top: 78px;
  text-align: center;
  .banner4_box{
    display: inline-block;
    width: 1200px;
    text-align: left;
  }
  h3 {
    display: inline-block;
    line-height: 67px;
    font-size: 48px;
    font-weight: 800;
    color: #333333;
  }
  .app {
    position: relative;
  }
  .app::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 4px;
    background-color: #000000;
    bottom: -8px;
    left: 60px;
  }
  .highlight {
    color: #ff7b00;
  }
  .line {
    display: inline-block;
    width: 1200px;
    height: 1px;
    background: #ff7b00;
    margin-top: 102px;
  }
  .one,
  .two,
  .three {
    float: left;
    margin-top: -50px;
    position: relative;
    .title::before {
      content: "";
      position: absolute;
      width: 56px;
      height: 56px;
      background-position: center;
      background-size: cover;
      top: 18px;
      left: -60px;
    }
  }
  .one:hover,
  .two:hover,
  .three:hover {
    .title,
    .text {
      color: #ff7b00;
    }
  }
  .title {
    height: 42px;
    font-size: 30px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 20px;
  }
  .text {
    width: 301px;
    line-height: 26px;
    font-size: 24px;
    font-weight: 500;
    color: #666666;
  }
  .one {
    .title::before {
      background-image: url(../../assets/download-icon5.png);
    }
    .text {
      font-size: 20px;
      line-height: 22px;
    }
  }
  .two,
  .three {
    margin-left: 112px;
  }
  .two {
    .title::before {
      background-image: url(../../assets/download-icon6.png);
    }
  }
  .three {
    .title::before {
      background-image: url(../../assets/download-icon7.png);
    }
  }
}
.banner5 {
  height: 480px;
  background-image: url(../../assets/download-banner4.png);
  background-position: center;
  background-size: cover;
  position: relative;
  h2 {
    line-height: 50px;
    font-size: 36px;
    font-weight: 800;
    color: #ffffff;
    letter-spacing: 1px;
    position: absolute;
    top: 78px;
    right: 574px;
  }
  h3 {
    width: 306px;
    line-height: 34px;
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 3px;
    position: absolute;
    top: 136px;
    right: 574px;
  }
  .ios {
    position: absolute;
    width: 306px;
    height: 80px;
    background-image: url(../../assets/download-ios2.png);
    background-position: center;
    background-size: cover;
    top: 212px;
    right: 574px;
    cursor: pointer;
  }
  .android {
    position: absolute;
    width: 306px;
    height: 80px;
    background-image: url(../../assets/download-android2.png);
    background-position: center;
    background-size: cover;
    top: 322px;
    right: 574px;
    cursor: pointer;
  }
  .qrcode-right {
    top: 210px;
    right: 370px;
  }
}
.banner6 {
  height: 930px;
  padding-top: 80px;
  background-image: url(../../assets/download-phone.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  h3 {
    display: inline-block;
    line-height: 67px;
    font-size: 48px;
    font-weight: 800;
    color: #333333;
  }
  .highlight {
    color: #ff7b00;
  }
  .info {
    display: inline-block;
    width: 1143px;
    line-height: 36px;
    height: 108px;
    font-size: 26px;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
  }
  .icon {
    width: 86px;
    height: 86px;
  }
  .title {
    line-height: 36px;
    font-size: 26px;
    font-weight: 700;
    color: #333333;
    display: block;
    padding-top: 94px;
    margin-bottom: 8px;
  }
  .text {
    width: 375px;
    height: 93px;
    font-size: 22px;
    font-weight: 500;
    color: #666666;
    text-align: left;
  }
  .left {
    float: left;
    margin-left: 364px;
    .lb {
      margin-top: 80px;
    }
    .icon {
      float: right;
    }
    .title {
      text-align: right;
    }
  }
  .right {
    float: right;
    margin-right: 368px;
    text-align: left;
    .icon {
      float: left;
    }
    .rb {
      margin-top: 80px;
    }
  }
}
.banner7 {
  text-align: center;
  .banner7_box{
    display: inline-block;
    width: 1200px;
  }
  h3 {
    display: inline-block;
    line-height: 67px;
    font-size: 48px;
    font-weight: 800;
    color: #333333;
  }
  .highlight {
    color: #ff7b00;
  }
  .info {
    display: inline-block;
    width: 1139px;
    line-height: 36px;
    height: 108px;
    font-size: 26px;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
    text-indent: 3em;
  }
  .picBox {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .picImg {
      width: 379px;
      height: 280px;
      display: block;
      overflow: hidden;
      margin-bottom: 30px;
    }
    .pic {
      width: 379px;
      height: 280px;
      margin-right: 31px;
      transition: all 1s;
    }
    .pic:hover {
      transform: scale(1.1);
      -ms-transform: scale(1.1); /* IE 9 */
      -moz-transform: scale(1.1); /* Firefox */
      -webkit-transform: scale(1.1); /* Safari 和 Chrome */
      -o-transform: scale(1.1); /* Opera */
    }
  }
  .btn {
    display: inline-block;
    width: 440px;
    line-height: 48px;
    background: #ff9430;
    border-radius: 4px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    margin-top: 28px;
  }
  .qrcode {
    position: absolute;
    width: 198px;
    height: 210px;
    background-image: url(../../assets/download-qrcode.png);
    background-position: center;
    background-size: cover;
    top: -210px;
    left: 122px;
  }
}
.banner8 {
  height: 540px;
  background: #f7f7f7;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    display: inline-block;
    line-height: 67px;
    font-size: 48px;
    font-weight: 800;
    color: #333333;
  }
  .highlight {
    color: #ff7b00;
    margin-left: 15px;
  }
  .info {
    display: inline-block;
    width: 640px;
    line-height: 36px;
    font-size: 22px;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .qrcode {
    width: 212px;
    height: 212px;
  }
  .input {
    width: 640px;
    height: 60px;
    margin-top: 20px;
  }
}
.banner9 {
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2020;
    .modalcode {
      position: absolute;
      top: 224px;
      left: 41%;
      .code {
        width: 362px;
        height: 403px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        background-image: url(../../assets/coded.png);
        background-position: center;
        background-size: cover;
      }
      .closed {
        width: 58px;
        height: 58px;
        cursor: pointer;
        margin: 20px auto 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>